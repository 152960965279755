/* eslint-disable no-case-declarations */
import {
  defaultInternationalization,
  defaultLanguage,
  Languages,
} from 'corporate-types';
import {
  capitalizeFirstLetter,
  internationalizationToLanguages,
  toKebabCaseWithAccentsCyrillicCJK,
} from 'corporate-utils';
import { Pages } from '../models/domain/enum';
import { MegamenuLink, Product, Slug } from '../models/domain/interfaces';
import { getAvailableStringFromCategoryByLocale } from './categories';

export function shrinkProduct(productDwh: Product): Product {
  return {
    sku: productDwh?.sku,
    skuFather: productDwh?.skuFather,
    name: productDwh?.name,
    nameFather: productDwh?.nameFather,
    categories: productDwh?.categories,
    categoriesCalculated: productDwh?.categoriesCalculated,
    descriptions: productDwh?.descriptions,
    children: productDwh?.children,
    images: productDwh?.images,
    cover: productDwh?.cover,
    link: productDwh?.link,
    defaultPrice: productDwh?.defaultPrice,
    brand: productDwh?.brand,
    otherProductsLink: productDwh?.otherProductsLink,
    categoriesCalculatedInfo: productDwh?.categoriesCalculatedInfo,
  };
}

export function shrinkMegaMenuLinkBefore(
  megamenuLinks: MegamenuLink[]
): MegamenuLink[] {
  const megaMenulinksCopy: MegamenuLink[] = JSON.parse(
    JSON.stringify(megamenuLinks)
  );
  for (const e of megaMenulinksCopy) {
    if (e.recommendedProduct) {
      e.recommendedProduct = {
        nameFather: e?.recommendedProduct?.nameFather,
        link: e?.recommendedProduct?.link,
        otherProductsLink: e?.recommendedProduct?.otherProductsLink,
        images:
          e?.recommendedProduct?.images && e?.recommendedProduct?.images?.[0]
            ? [e?.recommendedProduct?.images?.[0]]
            : [],
        categoriesCalculatedInfo:
          e?.recommendedProduct?.categoriesCalculatedInfo,
      } as any;

      const firstChild = [];
      if (e.firstChild) {
        for (const el of e.firstChild) {
          const childToAdd = {
            id: el?.id,
            idFather: el?.idFather,
            order: el?.order,
            depth: el?.depth,
            nameIt: el?.nameIt,
            nameEn: el?.nameEn,
            nameFr: el?.nameFr,
            nameDe: el?.nameDe,
            nameEs: el?.nameEs,
            namePt: el?.namePr, // sbagliato da DWH
            nameRu: el?.nameRu,
            nameCn: el?.nameCn,
            nameZh: el?.nameCn,
            msInfo: el?.msInfo,
            lft: el?.lft,
            rgt: el?.rgt,
            cmsInfo: el?.cmsInfo,
          };
          firstChild.push(childToAdd);
        }
      }
      e.firstChild = firstChild as any;
    }
  }

  return megaMenulinksCopy;
}

export function shrinkMegaMenuLinkAfter(
  megamenuLinks: MegamenuLink[],
  locale: string,
  slugAnalyzed: Slug
): MegamenuLink[] {
  const megaMenulinksCopy: MegamenuLink[] = JSON.parse(
    JSON.stringify(megamenuLinks)
  );

  const { type, slug } = slugAnalyzed;

  for (const e of megaMenulinksCopy) {
    if (e.recommendedProduct) {
      e.recommendedProduct = {
        nameFather: e?.recommendedProduct?.nameFather,
        link: e?.recommendedProduct?.link,
        otherProductsLink: e?.recommendedProduct?.otherProductsLink,
        images:
          e?.recommendedProduct?.images && e?.recommendedProduct?.images?.[0]
            ? [e?.recommendedProduct?.images?.[0]]
            : [],
        categoriesCalculatedInfo:
          e?.recommendedProduct?.categoriesCalculatedInfo,
      } as any;

      const firstChild = [];
      if (e.firstChild) {
        for (const el of e.firstChild) {
          const [categoryFather, categoryChild] = slug as string[];

          const categoryFatherFound = Object.keys(el)?.find?.((key: string) => {
            const value = el?.[key as any];
            return (
              value &&
              (
                toKebabCaseWithAccentsCyrillicCJK(value || '') as string
              )?.toLowerCase() === categoryFather
            );
          });

          const categoryChildFound = Object.keys(el)?.find?.((key: string) => {
            const value = el?.[key as any];

            return (
              value &&
              (
                toKebabCaseWithAccentsCyrillicCJK(value || '') as string
              )?.toLowerCase() === categoryChild
            );
          });

          switch (true) {
            case type === Pages.PRODUCT &&
              (!!categoryFatherFound || categoryChildFound):
            case type === Pages.CATEGORY &&
              (!!categoryFatherFound || categoryChildFound):
              firstChild.push(el);
              break;

            case type === Pages.CATEGORY_FATHER && !!categoryFatherFound:
              firstChild.push(el);
              break;

            default:
              const name = getAvailableStringFromCategoryByLocale(el, locale);

              const convertedLocale: Languages =
                (Object.values(Languages) as string[]).indexOf(locale) !== -1
                  ? (locale as Languages)
                  : internationalizationToLanguages(
                    locale || defaultInternationalization,
                    'packages/corporate-ui/src/utils/shrink.ts shrinkMegaMenuLink'
                  ) || defaultLanguage;

              const childToAdd = {
                id: el?.id,
                idFather: el?.idFather,
                depth: el?.depth,
                [`name${capitalizeFirstLetter(convertedLocale)}`]: name,
              } as any;
              firstChild.push(childToAdd);
              break;
          }
        }
      }
      e.firstChild = firstChild as any;
    }
  }

  return megaMenulinksCopy;
}
